<template>
  <div>
    <NavTitle class="mb16" title="充值订单表" :showBack="false">
      <template #right>
        <div class="flex flex-end items-center">
          <el-popover
            placement="bottom"
            width="150"
            trigger="hover">
            <el-button slot="reference" type="primary">新建+</el-button>
            <div class="btn-group">
              <div v-for="[type, value] in Object.entries(bizTypes)" :key="type" @click="$refs.addPopupRef.open({type})">{{value}}</div>
            </div>
          </el-popover>
          <div class="input-row mr16 ml16">
            <el-input class="input" placeholder="请输入商户ID/手机号" clearable v-model="form.keyword" @change="queryChange"></el-input>
            <el-button type="primary" icon="el-icon-search"  @click="queryChange"></el-button>
          </div>
          <el-date-picker
            class="mr16"
            v-model="form.screenDate"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="queryChange">
          </el-date-picker>
          <!-- <el-button type="primary" size="medium">
            <span class="flex items-center">
              <span>下载</span>
              <img class="icon-download" src="@/assets/images/download-w.png">
            </span>
          </el-button> -->
        </div>
      </template>
    </NavTitle>

    <div class="ml20 mr20">
      <el-menu :default-active="listType" mode="horizontal" active-text-color="#08C8BD"
      @select="menuSelect">
        <el-menu-item :index="item.type" v-for="(item, index) in menuList" :key="index">
          {{item.text}}
        </el-menu-item>
      </el-menu>
    </div>

    <div class="table-panel">
      <el-table :data="orderList" style="width: 100%" 
        :header-cell-style="{background: '#f0f1f3', color: '#333'}"
        v-loading="tableLoading">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" inline>
              <div class="flex items-center" style="padding-left: 60px;" v-for="(item, index) in scope.row.bizTransList" :key="index">
                <el-form-item label="订单类型：" style="width: 200px; margin: 0;">
                  <span>{{ item.orderTitle }}</span>
                </el-form-item>
                <el-form-item label="金额：" style="width: 200px; margin: 0;">
                  <span>{{ item.amount }}</span>
                </el-form-item>
                <!-- 会员 -->
                <template v-if="item.orderType == 1">
                  <el-form-item label="会员类型：" style="width: 200px; margin: 0;">
                    <span>{{ vipTypes[item.vipLevel] }}</span>
                  </el-form-item>
                  <el-form-item label="会员时长：" style="width: 200px; margin: 0;">
                    <span>{{ item.vipDuration }}</span>
                  </el-form-item>
                </template>
                <!-- 点券 -->
                <el-form-item label="数量：" v-if="item.orderType == 2 || item.orderType == 8" style="width: 200px; margin: 0;">
                  <span>{{ item.number }}</span>
                </el-form-item>
                <!-- 报名单 -->
                <template v-if="item.orderType == 3 || item.orderType == 9">
                  <el-form-item label="份数：" style="width: 200px; margin: 0;">
                    <span>{{ item.number }}</span>
                  </el-form-item>
                  <el-form-item label="cpc单价：" style="width: 200px; margin: 0;">
                    <span>{{ item.cpcPrice }}</span>
                  </el-form-item>
                </template>
                <!-- 首页弹窗广告 -->
                <template v-if="item.orderType == 15 || item.orderType == 16">
                  <el-form-item label="时长(天)：" style="width: 200px; margin: 0;">
                    <span>{{ item.number }}</span>
                  </el-form-item>
                </template>
              </div>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          prop="merchantName"
          label="订单类型"
          width="150">
          <template slot-scope="scope">
            <span :class="[scope.row.bizType == 1 || scope.row.bizType == 3 ? 'theme-text':'red-text']">{{bizTypes[scope.row.bizType]}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="merchantName"
          label="商户名称"
          width="150">
        </el-table-column>
        <el-table-column
          prop="mid"
          label="商户ID"
          width="80">
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="注册账号"
          width="120">
        </el-table-column>
        <!-- <el-table-column
          prop="cpcPrice"
          label="cpc单价"
          width="100">
        </el-table-column> -->
        <el-table-column
          prop="payer"
          label="支付方"
          width="100">
        </el-table-column>
        <el-table-column
          prop="payee"
          label="收款方"
          width="100">
        </el-table-column>
        <el-table-column
          prop="evidence"
          label="支付凭证"
          width="150">
          <template slot-scope="scope">
            <div class="img-list" v-if="scope.row.evidence">
              <div class="img" v-for="(pic, index) in scope.row.evidence.split(',')" :key="index">
                <LoadImage :src="pic" preview></LoadImage>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="amount"
          label="收款金额"
          width="100">
        </el-table-column>
        <!-- <el-table-column
          prop="invoice"
          label="是/否需要开票"
          width="120">
          <template slot-scope="scope">
            <el-select :value="scope.row.invoiceStatus" style="width: 70px;" :disabled="scope.row.invoiceStatus == 1" @change="handleInvoiceChange($event, scope.row.cid)">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="invoiceStatus"
          label="发票状态"
          width="120">
          <template #header>
            <el-dropdown @command="invoiceStatusCommand">
              <span style="color: #333333;" :class="{'theme-text': form.invoiceStatus || form.invoiceStatus === 0}">
                发票状态<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="type" v-for="([type, value], index) in Object.entries(invoiceStatusTypes)" :key="index">
                  <div :class="['flex', 'items-center', form.invoiceStatus === type ? 'theme-text':'']">
                    <img v-if="form.invoiceStatus === type" class="icon-selected" src="@/assets/images/icon-gouxuan.png">
                    <span>{{value}}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template slot-scope="scope">
            <!-- <span>{{invoiceStatusTypes[scope.row.invoiceStatus]}}</span> -->
            <el-select :value="scope.row.invoiceStatus" style="width: 100px;" :disabled="scope.row.invoiceStatus == 2" @change="handleInvoiceChange($event, scope.row.cid)">
              <el-option :label="value" :value="Number(type)" v-for="[type, value] in Object.entries(invoiceStatusTypes)" :key="type"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="invoiceHeader"
          label="发票抬头"
          width="100">
        </el-table-column>
        <el-table-column
          prop="invoiceInfo"
          label="发票信息"
          width="100">
        </el-table-column>
        <el-table-column
          prop="chargeTime"
          :label="`${listType == 0 ? '申请':'审核'}时间`"
          width="160"
          sortable>
        </el-table-column>
        <el-table-column
          prop="contractType"
          label="签约类型"
          width="100">
          <template #header>
            <el-dropdown @command="contractTypeCommand">
              <span style="color: #333333;" :class="{'theme-text': form.contractType || form.contractType === 0}">
                签约类型<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="type" v-for="([type, value], index) in Object.entries(contractTypeTypes)" :key="index">
                  <div :class="['flex', 'items-center', form.contractType === type ? 'theme-text':'']">
                    <img v-if="form.contractType === type" class="icon-selected" src="@/assets/images/icon-gouxuan.png">
                    <span>{{value}}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template slot-scope="scope">
            <span>{{contractTypeTypes[scope.row.contractType]}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="consultantName"
          label="商务顾问"
          width="100">
          <template #header v-if="counselorList && counselorList.length > 0">
            <el-dropdown @command="counselorCommand">
              <span style="color: #333333;" :class="{'theme-text': form.bid}">
                商务顾问<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item.bid" v-for="(item, index) in counselorList" :key="index">
                  <div :class="['flex', 'items-center', form.bid === item.bid ? 'theme-text':'']">
                    <img v-if="form.bid === item.bid" class="icon-selected" src="@/assets/images/icon-gouxuan.png">
                    <span>{{item.name}}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        
        <el-table-column
          prop="status"
          label="状态"
          width="100">
          <template slot-scope="scope">
            <span>{{chargeStatusTypes[scope.row.status]}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="memo"
          label="备注"
          width="150">
        </el-table-column>
        <el-table-column
          prop="refuseReason"
          label="驳回理由"
          width="150"
          v-if="listType == 2">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150"
          fixed="right"
          align="center"
          v-if="listType == 0">
          <template slot-scope="scope">
            <span class="theme-text pointer" v-if="scope.row.permission && scope.row.permission.includes(1)" @click="handlePassRecharge(scope.row.cid)">通过</span>
            <span class="ml8 red-text pointer" v-if="scope.row.permission && scope.row.permission.includes(2)" @click="$refs.refusePopupRef.open({cid: scope.row.cid})">驳回</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="flex justify-center mt16">
        <el-pagination
          layout="total, prev, pager, next, sizes"
          :current-page="page.current + 1"
          :total="page.total"
          :page-size="page.size"
          :page-sizes="[5, 10, 15, 20]"
          @current-change="pageCurrentChange"
          @size-change="pageSizeChange">
        </el-pagination>
      </div>
    </div>
    <!-- 新建 -->
    <AddOrderPopup ref="addPopupRef" @update="getOrderList"></AddOrderPopup>
    <!-- 查看凭证 -->
    <EvidencePopup ref="evidencePopupRef"></EvidencePopup>
    <!-- 审核驳回 -->
    <RefusePopup ref="refusePopupRef" @update="getOrderList()"></RefusePopup>
    <!-- 开票 -->
    <InvoicePopup ref="invoicePopupRef" @update="getOrderList()"></InvoicePopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import AddOrderPopup from './components/AddOrderPopup.vue'
import EvidencePopup from '../components/EvidencePopup.vue'
import RefusePopup from './components/RefusePopup.vue'
import InvoicePopup from './components/InvoicePopup.vue'
import LoadImage from '@/components/LoadImage'
import { queryRechargeOrderApi, accessibleConsultantsApi, auditOrderApi, modifiedOrderInvoiceApi } from '@/api/admin.js'
export default {
  components: {
    NavTitle,
    LoadImage,
    AddOrderPopup,
    EvidencePopup,
    RefusePopup,
    InvoicePopup
  },
  data() {
    return {
      bizTypes: {
        1: '充值订单',
        2: '退款订单',
        3: '缴纳保证金',
        4: '保证金退款'
      },
      invoiceStatusTypes: {
        0: '不需要',
        1: '待开票',
        2: '已开票',
      },
      contractTypeTypes: {
        1: '新签',
        2: '复购',
      },
      vipTimeTypes: {
        30:'月度',
        90:'季度',
        365:'年度',
      },
      vipTypes: {
        0:'普通会员',
        1:'青铜会员',
        2:'白银会员',
        3:'黄金会员',
        4:'会员已过期'
      },
      chargeStatusTypes: {
        0: '待审核',
        1: '审核通过',
        2: '审核驳回'
      },
      tableLoading: false,
      page: {
        current: 0,
        size: 10,
        total: 0
      },
      form: {
        keyword: '',
        screenDate: [],
        bid: '',
        invoiceStatus: '',
        contractType: '',
        orderType: ''
      },
      orderList: [],
      counselorList: [],
      listType: '0',
      menuList: [
        {text: '待审核', type: '0'},
        {text: '审核通过', type: '1'},
        {text: '审核驳回', type: '2'},
      ]
    }
  },
  created() {
    this.getOrderList()
    this.getCounselorList()
  },
  methods: {
    menuSelect(val) {
      this.listType = val
      this.page.current = 0
      this.getOrderList()
    },
    getOrderList() {
      const formData = {
        pageSize: this.page.size,
        startPage: this.page.current,
        keyword: this.form.keyword,
        invoiceStatus: this.form.invoiceStatus ? Number(this.form.invoiceStatus) : '',
        contractType: this.form.contractType ? Number(this.form.contractType) : '',
        orderType: this.form.orderType,
        bid: this.form.bid,
        orderStatus: Number(this.listType)
      }
      if(this.form.screenDate?.length > 0) {
        Object.assign(formData, {
          startTime: this.form.screenDate[0],
          endTime: this.form.screenDate[1]
        })
      }
      console.log('formData', formData)
      this.tableLoading = true
      queryRechargeOrderApi(formData).then(res => {
        console.log('订单列表', res.data)
        this.tableLoading = false
        if(res.data) {
          this.orderList = res.data.businessCommissions || []
          this.page.total = res.data.total || 0
        }
      }).catch(err => {
        this.tableLoading = false
        this.$tips({message: err, type:'error'})
      })
    },
    getCounselorList() {
      accessibleConsultantsApi().then(res => {
        console.log('全部商务顾问', res)
        this.counselorList = res.data?.consultantList || []
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    checkEvidence(evidence) {
      this.$refs.evidencePopupRef.open({evidence})
    },
    invoiceStatusCommand(command) {
      this.form.invoiceStatus = this.form.invoiceStatus === command ? '' : command
      this.queryChange()
    },
    contractTypeCommand(command) {
      this.form.contractType = this.form.contractType === command ? '' : command
      this.queryChange()
    },
    orderTypeCommand(command) {
      this.form.orderType = this.form.orderType === command ? '' : command
      this.queryChange()
    },
    counselorCommand(command) {
      console.log(command)
      this.form.bid = this.form.bid === command ? '' : command
      this.queryChange()
    },
    pageCurrentChange(e) {
      console.log('pageCurrentChange', e)
      this.page.current = e - 1
      this.getOrderList()
    },
    pageSizeChange(size) {
      this.page.size = size
      this.getOrderList()
    },
    queryChange() {
      this.page.current = 0
      this.getOrderList()
    },
    handleInvoiceChange(e, cid) {
      console.log('开票状态改变', e)
      if(e == 2) {
        this.$refs.invoicePopupRef.open({cid})
      } else {
        const formData = {
          cid,
          invoiceStatus: e
        }
        console.log('formData', formData)
        modifiedOrderInvoiceApi(formData).then(res => {
          console.log('开票结果', res)
          this.getOrderList()
        }).catch(err => {
          this.$tips({message: err, type:'error'})
        })
      }

    },
    handlePassRecharge(cid) {
      this.$confirm('确认审核通过?').then(action => {
        if(action == 'confirm') {
          auditOrderApi({
            cid,
            status: 1
          }).then(res => {
            console.log('改变充值状态', res)
            this.$tips({message: res.msg, type:'success'})
            this.getOrderList()
          }).catch(err => {
            this.$tips({message: err, type:'error'})
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__expanded-cell {
  background: #F6F6F6 !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  border-radius: 32px;
}
::-webkit-scrollbar-track {
  background-color: #F6F6F6;
  border-radius: 32px;
}
.input-row {
  width: 300px;
  display: flex;
  align-items: center;
}
.icon-download {
  width: 18px;
  height: 18px;
  margin-left: 4px;
}
.table-panel {
  background: #fff;
  padding: 10px;
  margin: 16px 20px 0;
}
.icon-selected {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-left: -4px;
}
.img-list {
  display: flex;
  width: 150px;
  overflow-x: auto;
  
  .img {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 4px;
    border-radius: 4px;
    overflow: hidden;
  }
}
.btn-group {
  div {
    height: 38px;
    line-height: 38px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
</style>